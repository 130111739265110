.machine-container {
  display: flex;
  flex-direction: column;
}

.gif-container {
  display: flex;
  flex-direction: column;
}

.gif-grid {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin: 0;
  padding: 20px 0 20px 0;
}

.gif-grid .gif-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-self: center;
  align-self: center;
}

.gif-item img {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
}
